<template>
  <UserTop :title="title" @userInfo="getUserInfo">
    <div class="right-box">
      <div class="user-set">
        <div class="set-box" v-if="userSetInfo">
          <div class="set-item">
            <div class="title">用户头像</div>
            <div class="info">
              <div class="info-img">
                <img :src="require(`@/assets/images/avatar/${userSetInfo.headImgUrl}`)" alt="">
              </div>
            </div>
            <div class="btn" @click="openDialog('img')">修改</div>
          </div>
          <div class="set-item">
            <div class="title">用户昵称</div>
            <div class="info">
              <span>{{userSetInfo.nickname}}</span>
            </div>
            <div class="btn" @click="openDialog('name')">修改</div>
          </div>
          <div class="set-item">
            <div class="title">用户手机</div>
            <div class="info">
              <span>{{userSetInfo.phone ? userSetInfo.phone : '未绑定'}}</span>
            </div>
            <div class="btn" v-if="!userSetInfo.phone" @click="openDialog('phone')">绑定</div>
            <div class="btn col" v-else>已绑定</div>
          </div>
          <div class="set-item">
            <div class="title">用户ID</div>
            <div class="info">
              <span>{{userSetInfo.id}}</span>
            </div>
          </div>
          <div class="set-item">
            <div class="title">会员等级</div>
            <div class="info" v-if="userSetInfo && userSetInfo.vipInfoVos.length">
              <span v-for="item in userSetInfo.vipInfoVos">{{ item.title }}</span>
            </div>
            <div class="info" v-else>
              <span>未成为会员</span>
            </div>
            <div class="btn" @click="goMenu('/user_promot/member')">
              {{userSetInfo.vipInfoVos.length ? '升级' : '加入'}}
            </div>
          </div>
          <div class="set-item">
            <div class="title">会员有效期</div>
            <div class="info" v-if="userSetInfo && userSetInfo.vipInfoVos.length">
<!--              <span>{{userSetInfo.vipInfoVos.length ? '至 ' + userSetInfo.vipInfoVos[0].vipEndTime : '-&#45;&#45;'}}</span>-->
              <span v-for="item in userSetInfo.vipInfoVos">至 {{ item.vipEndTime }}</span>
            </div>
            <div class="info" v-else>
              <span>---</span>
            </div>
            <div class="btn" @click="goMenu('/user_promot/member')" v-if="userSetInfo.vipInfoVos.length">
              续期
            </div>
          </div>
        </div>
      </div>

      <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        center
        class="width-show">
        <div class="avatar-box" v-if="dialogType === 'img'">
          <ul>
            <li :class="{'active': item.active === 1}"
                v-for="(item,index) in avatarList"
                @click="selectAvatar(index,item.imgUrl)">
              <img :src="require(`@/assets/images/avatar/${item.imgUrl}`)" alt="">
            </li>
          </ul>
        </div>
        <div class="input-box" v-if="dialogType === 'name'">
          <el-form :model="formNickname"
                   :rules="rulesNickname"
                   ref="ruleNickname"
                   :label-position="'top'"
                   class="demo-ruleForm">
            <el-form-item label="昵称" prop="userNickname">
              <el-input placeholder="请输入昵称"
                        v-model="formNickname.userNickname"
                        clearable>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="input-box" v-if="dialogType === 'phone'">
          <el-form :model="formPhone"
                   :rules="rulesPhone"
                   ref="rulePhone"
                   :label-position="'top'"
                   class="demo-ruleForm">
            <el-form-item label="手机号" prop="userPhone">
              <el-input placeholder="请输入手机号"
                        v-model="formPhone.userPhone"
                        maxlength="11"
                        minlength="11"
                        clearable>
              </el-input>
            </el-form-item>
            <el-form-item class="list-item" label="验证码" prop="userAuthCode">
              <el-input class="list-input"
                        placeholder="请输入验证码"
                        v-model="formPhone.userAuthCode"
                        clearable>
              </el-input>
              <el-button class="text-btn" type="text" :disabled="isCountDown" @click="sendCode">{{ countDownText }}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">取 消</el-button>
          <el-button v-if="dialogType === 'img'" type="primary" @click="submitVisible">确 定</el-button>
          <el-button v-if="dialogType === 'name'" type="primary" @click="submitVisible('ruleNickname')">确 定</el-button>
          <el-button v-if="dialogType === 'phone'" type="primary" @click="submitVisible('rulePhone')">确 定</el-button>
        </span>
      </el-dialog>

    </div>
  </UserTop>
</template>
<script>
import UserTop from "@/views/user_promot/components/userTop"
import UserPromotApi from "@/api/user"
import {EventBus} from "@/utils/eventBus"
export default {
  name: 'setUp',
  components: {
    UserTop
  },
  data() {
    return {
      title: '个人设置',
      userInfo: {
        isLogin: false, // 用户登录状态
        username: '',
        nickname: '',
        token: ''
      },
      userSetInfo: '',
      avatarList: [{
        active: 0,
        imgUrl: 'photo1.jpg',
      },{
        active: 0,
        imgUrl: 'photo2.jpg',
      },{
        active: 0,
        imgUrl: 'photo3.jpg',
      },{
        active: 0,
        imgUrl: 'photo4.jpg',
      },{
        active: 0,
        imgUrl: 'photo5.jpg',
      },{
        active: 0,
        imgUrl: 'photo6.jpg',
      },{
        active: 0,
        imgUrl: 'photo7.jpg',
      },{
        active: 0,
        imgUrl: 'photo8.jpg',
      },{
        active: 0,
        imgUrl: 'photo9.jpg',
      },{
        active: 0,
        imgUrl: 'photo10.jpg',
      },{
        active: 0,
        imgUrl: 'photo11.jpg',
      },{
        active: 0,
        imgUrl: 'photo12.jpg',
      },{
        active: 0,
        imgUrl: 'photo13.jpg',
      },{
        active: 0,
        imgUrl: 'photo14.jpg',
      },{
        active: 0,
        imgUrl: 'photo15.jpg',
      },{
        active: 0,
        imgUrl: 'photo16.jpg',
      }],
      dialogVisible: false,
      dialogType: '',
      dialogTitle: '',
      userHeadImgUrl: '',
      formNickname: {
        userNickname: '',
      },
      rulesNickname: {
        userNickname: [
          { required: true, message: '请输入昵称', trigger: 'blur' },
        ]
      },
      formPhone: {
        userPhone: '',
        userAuthCode: ''
      },
      rulesPhone: {
        userPhone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入正确手机号', trigger: 'blur' },
          { len: 11, message: '请输入正确手机号', trigger: 'blur' }
        ],
        userAuthCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ]
      },
      codeShow: false,
      countDown: 0,
      timer: null
    }
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // console.log(this.userInfo)
    this.getUserDetailVo()
  },
  computed: {
    isCountDown() {
      return this.countDown > 0;
    },
    countDownText() {
      // console.log(this.isCountDown)
      return this.isCountDown ? `${this.countDown}s后重新发送` : '发送验证码';
    }
  },
  methods: {
    getUserDetailVo(){
      UserPromotApi.getUserDetailVo(this.userInfo.token).then(res => {
        this.userSetInfo = res.data
        localStorage.setItem('userSetInfo', JSON.stringify(res.data))
        // 传递 刷新用户信息 的消息
        EventBus.$emit("userSetInfo", 'userInfoTrue')
      }).catch(() => {
      })
    },
    getUserInfo(msg){
      if (msg && msg.user === false){
        this.userInfo = this.$options.data().userInfo
        localStorage.removeItem('userInfo')
      }else {
        this.userInfo = msg.info
      }
    },
    openDialog(type){
      this.dialogType = type
      if(type === 'img'){
        this.dialogTitle = '修改头像'
        this.avatarList.forEach(item => item.active = 0)
      }else if(type === 'name'){
        this.dialogTitle = '修改昵称'
      }else if(type === 'phone'){
        this.dialogTitle = '绑定手机号'
      }
      this.dialogVisible = true
    },
    closeDialog(){
      this.dialogVisible = false
      if(this.dialogType === 'img'){
        this.avatarList.forEach(item => item.active = 0)
        this.userHeadImgUrl = ''
      }else if(this.dialogType === 'name'){
        this.userNickname = ''
        this.resetForm('ruleNickname')
      }else if(this.dialogType === 'phone'){
        this.userPhone = ''
        this.resetForm('rulePhone')
      }
      this.dialogTitle = ''
    },
    selectAvatar(index,img){
      this.avatarList.forEach(item => item.active = 0)
      this.avatarList[index].active = 1
      this.userHeadImgUrl = img
    },
    submitVisible(formName){
      let _this = this;
      // console.log(formName)
      // if(formName){
      //   _this.$refs[formName].validate((valid) => {
      //     if (valid) {
      //       // alert('submit!');
      //       if(this.dialogType === 'name'){
      //         _this.updateNickName()
      //       }else if(this.dialogType === 'phone'){
      //         _this.postBindPhone()
      //       }
      //       _this.dialogVisible = false
      //     } else {
      //       // console.log('error submit!!');
      //       return false;
      //     }
      //   });
      // }else {
      //   if(this.dialogType === 'img'){
      //     _this.updateHeadImg()
      //   }
      // }

      if(this.dialogType === 'img'){
        _this.updateHeadImg()
        _this.dialogVisible = false
      }else {
        _this.$refs[formName].validate((valid) => {
          if (valid) {
            // alert('submit!');
            if(this.dialogType === 'name'){
              _this.updateNickName()
            }else if(this.dialogType === 'phone'){
              _this.postBindPhone()
            }
            _this.dialogVisible = false
          } else {
            // console.log('error submit!!');
            return false;
          }
        })
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 修改头像API
    updateHeadImg(){
      let _this = this
      UserPromotApi.updateHeadImg(_this.userHeadImgUrl,_this.userInfo.token).then(res => {
        _this.$message.success('修改成功')
        _this.getUserDetailVo()
      })
    },
    // 修改昵称API
    updateNickName(){
      let _this = this
      UserPromotApi.updateNickName(_this.formNickname.userNickname,_this.userInfo.token).then(res => {
        _this.$message.success('修改成功')
        _this.getUserDetailVo()
      })
    },
    // 绑定手机API
    postBindPhone(){
      let _this = this
      let data = {
        authCode: this.formPhone.userAuthCode,
        phone: this.formPhone.userPhone
      }
      UserPromotApi.postBindPhone(data,_this.userInfo.token).then(res => {
        _this.$message.success('修改成功')
        clearInterval(_this.timer)
        _this.timer = null
        _this.getUserDetailVo()
      })
    },
    sendCode() {
      if (this.isCountDown) return;
      // 验证手机号 并 发送验证码的请求
      this.$refs.rulePhone.validateField('userPhone', (error) => {
        if (!error) {
          // console.log('发送验证码');
          this.postSms()
        }
      });
    },
    // 验证码定时器 60s
    startCountDown() {
      if (this.timer) return;
      this.countDown = 60;
      this.timer = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown -= 1;
        } else {
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
    },
    postSms(){
      let _this = this
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      UserPromotApi.postSms(_this.formPhone.userPhone,key,time,infoMd5,_this.userInfo.token).then(res => {
        // 验证码已发送 启动定时器
        _this.startCountDown()
        _this.$message.success('验证码已发送')
      })
    },
    goMenu(url){
      this.$router.push(url)
    }
  }
}
</script>
<style scoped lang="less">
.right-box{
  width: calc(100% - 200px);
  .user-set{
    width: 100%;
    border-radius: 15px;
    border: 2px solid #ffffff;
    background-color: rgba(255, 255, 255, 0.3);
    .set-box{
      margin: 15px;
      border-radius: 15px;
      background-color: #ffffff;
      box-shadow: 0 0 5px rgba(0, 140, 250, 0.15);
      .set-item{
        padding: 20px 0;
        margin: 0 4%;
        border-bottom: 1px solid #f4f4f4;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .title{
          width: 15%;
          min-width: 100px;
          font-size: 14px;
          font-weight: bold;
          color: #3c3c3c;
        }
        .info{
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          .info-img{
            width: 60px;
            height: 60px;
            border-radius: 60px;
            border: 1px solid #e3e3e3;
            img{
              width: 100%;
              height: 100%;
              border: 0;
              margin: 0;
              border-radius: 50%;
            }
          }
          span{
            //display: block;
            min-width: 220px;
            color: #606060;
          }
        }
        .btn{
          margin-left: auto;
          width: 76px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          border-radius: 5px;
          font-size: 14px;
          color: #ffffff;
          background-color: #7fc6ff;
          cursor: pointer;
        }
        .col{
          background-color: #aad69b;
        }
      }
    }
  }
  .avatar-box{
    ul{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      li{
        width: 16.6%;
        margin-bottom: 30px;
        text-align: center;
        cursor: pointer;
        img{
          width: 60px;
          height: 60px;
          border-radius: 60px;
          margin: 0;
          border: 1px solid #e3e3e3;
        }
      }
      li.active,li:hover{
        img{
          border: 2px solid #409EFF;
        }
      }
    }
  }

  .input-box{
    width: 70%;
    margin: 0 auto;
    .list-item{
      position: relative;
      margin-bottom: 10px;
      .list-input{
        width: calc(100% - 120px);
      }
      .text-btn{
        position: absolute;
        top: 0;
        right: 10px;
        width: 90px;
        z-index: 9;
      }
    }
    .el-form-item{
      margin-bottom: 15px;
      /deep/ .el-form-item__label{
        line-height: 30px !important;
      }
    }
  }

  .width-show{
    /deep/ .el-dialog{
      border-radius: 15px;
      width: 40% !important;
      .el-dialog__title{
        font-size: 16px !important;
      }
    }
  }
}

@media screen and (max-width: 900px){
  .right-box {
    width: 100%;
    .user-set{
      .set-box{
        margin: 5px;
        .set-item{
          .title{
            min-width: 80px;
            font-size: 12px;
          }
          .info{
            span{
              min-width: 50%;
              font-size: 12px;
            }
          }
          .btn{
            max-width: 48px;
            min-width: 48px;
            height: 28px;
            line-height: 28px;
            font-size: 12px;
          }
        }
      }
    }

    .width-show{
      /deep/ .el-dialog{
        width: 80% !important;
      }
    }
    .avatar-box {
      ul{
        li{
          width: 25%;
          img{
            width: 50px;
            height: 50px;
          }
        }
      }
    }
    .input-box{
      width: 100%;
    }
  }
}
</style>
